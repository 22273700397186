/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import Breadcrumb from "../breadcrumb/breadcrumb-auto";
import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";
import DailyHours from "../branch-info/daily-hours";
import BranchServices from "../branch-info/branch-services";
import SEO from "../../components/seo/seo";
import Modal from "react-bootstrap/Modal";
import HolidaysModal from "../holidays-modal";
import NotificationAlert from "../../components/notifications/notification-alert";
import nameToId from "../../helpers/nameToId";

// Styles
import "./branch.bootstrap.scss";
import styles from "../../pages/commercial-banking/treasury-management-services/express.module.scss";
import BankerHeadshot from "../../components/banker-headshot";

export const query = graphql`
  query spanishBranchQuery($pageURL: String) {
    spanishBranch: strapiBranchLocations(PageURL: { eq: $pageURL }) {
      PageURL
      Title
      BranchId
      YextCode
      MSBookingsBranchName
      Address {
        City
        State
        StreetAddress
        ZipCode
        BranchGeoLocation {
          id
          Lat
          Lng
          Zoom
        }
      }
      PhoneNumber
      EmailAddress
      BranchImageUrl
      BranchServices {
        TellerServices
        WalkUpATM
        DriveThroughATM
        DriveThroughBanking
        SafeDepositBoxes
      }
      LobbyHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      DriveUpHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      SpanishDisruptionNotice
      SEO {
        MetaDescription
        MetaTitle
        Schema {
          address {
            _type
            addressCountry
            addressLocality
            addressRegion
            postalCode
            streetAddress
          }
          _context
          _type
          amenityFeature {
            _type
            hoursAvailable {
              _type
              closes
              dayOfWeek
              opens
            }
            name
            value
          }
          branchCode
          email
          geo {
            _type
            latitude
            longitude
          }
          image
          name
          openingHoursSpecification {
            _type
            closes
            dayOfWeek
            opens
          }
          parentOrganization {
            _type
            logo
            name
            url
          }
          telephone
          url
        }
      }
      OgImageUrl
      branch_state {
        StateCode
        Slug
      }
      loan_officers {
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      mortgage_bankers {
        Active
        FirstName
        LastName
        JobTitle
        EmailAddress
        PhoneNumber
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    defaultBranchPhoto: file(relativePath: { eq: "branches/wafd-bank-branch-default-1000.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 1200
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const SpanishBranchTemplate = ({ location, data }) => {
  const branch = data.spanishBranch;
  const StateCode = branch.branch_state.StateCode;
  const StateName =
    branch.Address.State === "NewMexico" || branch.Address.State === "New Mexico"
      ? "Nuevo Mexico"
      : branch.Address.State;

  const pageLocation = { location };

  // 2022 Holidays Modal
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const handleCloseModal = () => setShowHolidayModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setShowHolidayModal(true);
  };

  // TODO: the case sensitivity of these keys must match what is in Strapi data, too brittle.
  let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let now = new Date();

  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return weekdays[day];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(weekDay);
  }, []);

  const [showLobbyHours, setShowLobbyHours] = useState(false);
  const handleShowLobbyHours = () => {
    setShowLobbyHours(!showLobbyHours);
  };

  const [showDriveUpHours, setShowDriveUpHours] = useState(false);
  const handleShowDriveUpHours = () => {
    setShowDriveUpHours(!showDriveUpHours);
  };

  const todaysLobbyHours = branch.LobbyHours.AllClosed
    ? "Cerrado"
    : branch.LobbyHours[today] !== null
    ? branch.LobbyHours[today]
    : "Desconocido";

  const todaysDriveUpHours = branch.DriveUpHours?.AllClosed
    ? "Cerrado"
    : branch.DriveUpHours !== null && branch.DriveUpHours[today] !== null
    ? branch.DriveUpHours[today]
    : "Desconocido";

  const todaysLobbyHoursHtml = todaysLobbyHours?.match(/closed/i)
    ? "Lobby <strong>Cerrado hoy</strong>"
    : "Lobby Abierto Hoy: <strong>" + todaysLobbyHours + "</strong>";

  const todaysDriveUpHoursHtml = todaysDriveUpHours?.match(/closed/i)
    ? "Drive Thru <strong>Cerrado hoy</strong>"
    : "Drive Thru Abierto Hoy: <strong>" + todaysDriveUpHours + "</strong>";

  const openAccountBtnData = {
    id: "open-account-cta",
    containerClass: "mb-4",
    type: "link",
    class: "btn-link w-100 w-sm-auto",
    text: "Abrir una cuenta",
    url: "/es/abrir-cuenta-bancaria-en-linea"
  };

  const viewInGoogleMapUrl =
    "https://maps.google.com/maps?q=" +
    branch.Address.StreetAddress +
    "%2c" +
    branch.Address.City +
    "%2c" +
    StateCode +
    "%2c" +
    branch.Address.ZipCode;

  // provide the static URLs for og:image meta tags
  const branchOgImage = branch.OgImageUrl
    ? `https://www.wafdbank.com` + branch.OgImageUrl
    : branch.BranchImageUrl
    ? `https://www.wafdbank.com${branch.BranchImageUrl}`
    : `https://www.wafdbank.com` + getSrc(data.defaultBranchPhoto);

  const fullPageUrl = "https://www.wafdbank.com" + pageLocation.location.pathname;

  const metaTitle =
    //Bellevue, WA branch has a completely different metadata as it's from LBS and also it only provides Commercial Services
    StateCode === "CA"
      ? branch.Title + ", California Bank - Cuentas corrientes y de ahorro"
      : branch.PageURL === "/locations/washington/bellevue/central"
      ? "Banca comercial en Central Bellevue, WA"
      : "Ubicación de sucursal de banco en " + branch.Title + ", " + StateName;
  const metaDescription =
    StateCode === "CA"
      ? "Bienvenido a WaFd Bank of California en " +
        branch.Title +
        " (formalmente Luther Burbank Savings). Ofrecemos cuentas corrientes, de ahorro y de CD y más en " +
        branch.Title +
        ", CA."
      : branch.PageURL === "/locations/washington/bellevue/central"
      ? "Bienvenido a WaFd Bank en Central Bellevue, WA (formalmente Luther Burbank Savings). Nuestra sucursal de Central Bellevue ofrece servicios de banca comercial y más."
      : "En nuestra sucursal de banco en " +
        branch.Title +
        ", " +
        StateName +
        " le atenderemos con gusto. Encuentre la sucursal de WaFd Bank más cercana a usted y visítenos.";

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: fullPageUrl
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: branchOgImage
      }
    ]
  };

  function translateLobbyHours() {
    let spanishLobbyHours = { ...branch.LobbyHours };
    for (const prop in spanishLobbyHours) {
      if (spanishLobbyHours[prop] === "Closed") {
        spanishLobbyHours[prop] = "Cerrada";
      }
    }
    return spanishLobbyHours;
  }

  function translateDriveUpHours() {
    let spanishDriveIpHours = { ...branch.DriveUpHours };
    for (const prop in spanishDriveIpHours) {
      if (spanishDriveIpHours[prop] === "Closed") {
        spanishDriveIpHours[prop] = "Cerrada";
      }
    }
    return spanishDriveIpHours;
  }

  // console.log(
  //   `Found ${branch.loan_officers.length} Personal Bankers and ${branch.mortgage_bankers.length} Mortgage Bankers`
  // );
  let loanOfficers = [...branch.loan_officers, ...branch.mortgage_bankers.filter((mb) => mb.Active === true)];

  // console.log(`Found ${loanOfficers.length} Loan Officers (total)`);

  loanOfficers.forEach(function (loanOfficer) {
    if (loanOfficer.JobTitle === "BranchManager") {
      loanOfficer.JobTitle = "Gerente de Sucursal";
    } else if (loanOfficer.JobTitle === "BranchManagerTrainee") {
      loanOfficer.JobTitle = "Gerente entrenando de Sucursal";
    } else if (loanOfficer.JobTitle === "AssistantBranchManager") {
      loanOfficer.JobTitle = "Asistente de Gerente Sucursal";
    } else if (loanOfficer.JobTitle === "PersonalBanker") {
      loanOfficer.JobTitle = "Banquero Personal";
    } else if (loanOfficer.JobTitle === "MortgageBanker") {
      loanOfficer.JobTitle = "Banquero Hipotecario";
    } else if (loanOfficer.JobTitle === "LoanOfficer") {
      loanOfficer.JobTitle = "Oficial de préstamo";
    } else if (loanOfficer.JobTitle === "FirstTimeHomeBuyerSpecialist") {
      loanOfficer.JobTitle = "Especialista de Compras de Casa por Primera Vez";
    }
  });

  const branchManager = loanOfficers.find((lo) => lo.JobTitle === "Gerente de Sucursal");
  if (loanOfficers && branchManager) {
    loanOfficers = loanOfficers.filter((n) => n.JobTitle !== "Gerente de Sucursal");
    loanOfficers.unshift(branchManager);
  }

  const hasDisruptionNotice =
    branch && branch.SpanishDisruptionNotice && branch.SpanishDisruptionNotice.trim().length !== 0;

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb {...{ location }} />
      {/* Disruption Notice */}
      {hasDisruptionNotice && (
        <section className="container pb-0">
          <NotificationAlert
            type="warning"
            id="branch-disruption-notification-alert"
            class="mb-0"
            bodyText={branch.SpanishDisruptionNotice}
          />
        </section>
      )}
      {/* Branch Info Section */}
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-success">WaFd Bank {branch.Title}</h1>
            <Button {...openAccountBtnData} />
            {/* Branch Address */}
            <div className="d-flex mb-3">
              <Icon name="map-marker-alt" lib="far" class="text-primary mr-2 mt-1" />
              <a
                className="text-decoration-none"
                href={viewInGoogleMapUrl}
                id="branch-address-cta"
                target="_blank"
                rel="noopener noreferrer"
              >
                {branch.Address.StreetAddress}, {branch.Address.City},{" "}
                {branch.Address.State === "NewMexico" ? "Nuevo Mexico" : branch.Address.State} {branch.Address.ZipCode}
              </a>
            </div>
            {/* Today Lobby Hours */}
            <div className="d-flex flex-column flex-sm-row mb-3">
              <div>
                <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                <span
                  id="today-branch-hours"
                  dangerouslySetInnerHTML={{
                    __html: todaysLobbyHoursHtml
                  }}
                ></span>
              </div>
              <div className="ml-sm-2 d-none d-md-flex">
                <a href="#branch-lobby-hours" className="text-decoration-none" id="branch-lobby-hours-anchor-link">
                  <Icon name="arrow-down" class="mr-1" lib="far" />
                  Horario del lobby
                </a>
              </div>
              <div className="ml-sm-2 d-flex d-md-none">
                <button
                  className="btn btn-anchor-link no-min-width px-0 text-left"
                  onClick={handleShowLobbyHours}
                  id="show-hide-lobby-hours"
                >
                  <Icon name={showLobbyHours ? "chevron-up" : "chevron-down"} class="mr-1" lib="fas" />
                  {showLobbyHours ? "Hide Hours" : "Ver Horarios"}
                </button>
              </div>
            </div>
            {showLobbyHours && (
              <div className="mb-4">
                <DailyHours lang={"es"} {...translateLobbyHours()} />
                <button
                  className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                  onClick={handleShowModal}
                >
                  <Icon name="calendar" lib="far" class="mr-2" />
                  Días festivos 2024
                </button>
              </div>
            )}
            {/* Today Drive Up Hours */}
            {branch.DriveUpHours && (
              <>
                <div className="d-flex flex-column flex-sm-row my-3">
                  <div>
                    <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                    <span
                      id="today-drive-thru-hours"
                      dangerouslySetInnerHTML={{
                        __html: todaysDriveUpHoursHtml
                      }}
                    ></span>
                  </div>
                  <div className="ml-sm-2 d-none d-md-flex">
                    <a
                      href="#branch-drive-thru-hours"
                      className="text-decoration-none"
                      id="branch-drive-thru-anchor-link"
                    >
                      <Icon name="arrow-down" class="mr-1" lib="far" />
                      Horas de Autoservicio
                    </a>
                  </div>
                  <div className="ml-sm-2 d-flex d-md-none">
                    <button
                      className="btn btn-anchor-link no-min-width px-0 text-left"
                      onClick={handleShowDriveUpHours}
                      id="show-hide-drive-up-hours"
                    >
                      <Icon name={showDriveUpHours ? "chevron-up" : "chevron-down"} class="mr-1" lib="fas" />
                      {showDriveUpHours ? "Hide Hours" : "Ver Horarios"}
                    </button>
                  </div>
                </div>
                {showDriveUpHours && <DailyHours lang={"es"} {...translateDriveUpHours()} />}
              </>
            )}
            {/* Branch Phone Number */}
            <div className="mb-3">
              <Icon name="phone" lib="far" class="text-primary mr-2" />
              <a className="text-decoration-none" id="branch-phone-cta" href={`tel:${branch.PhoneNumber}`}>
                {branch.PhoneNumber}
              </a>
            </div>
            {/* Branch Email Address */}
            <div>
              <Icon name="envelope" lib="far" class="text-primary mr-2" />
              <a className="text-decoration-none" id="branch-email-cta" href={`mailto:${branch.EmailAddress}`}>
                {branch.EmailAddress}
              </a>
            </div>
          </div>

          <div className="col-md-6 d-none d-md-block">
            {branch.BranchImageUrl ? (
              <img
                src={branch.BranchImageUrl}
                className="border-radius-12 mw-100"
                alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
              />
            ) : null}
          </div>
        </div>
      </section>

      {/* Branch Services Section */}
      <section className="container section-padding-adjusted" id="branch-information">
        <h2 className="text-success d-none d-md-block">Branch Information</h2>
        <div className="row">
          {/* Lobby Hours */}
          <div className="col-md-4 d-none d-md-block" id="branch-lobby-hours">
            <h3>Horario del lobby</h3>
            <DailyHours lang={"es"} {...translateLobbyHours()} />
            <p>
              <button
                className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                onClick={handleShowModal}
              >
                <Icon name="calendar" lib="far" class="mr-2" />
                Días festivos 2024
              </button>
            </p>
          </div>
          {/* Current Year Holidays Modal */}
          <Modal show={showHolidayModal} onHide={handleCloseModal}>
            <Modal.Header className="border-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
              <HolidaysModal />
            </Modal.Body>
            <Modal.Footer>
              <button
                className={`text-left text-black font-weight-bold ${styles.buttonTransparent}`}
                style={{ cursor: "pointer" }}
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
            </Modal.Footer>
          </Modal>
          {/* Drive Thru Hours */}
          {branch.BranchServices.DriveThroughBanking && (
            <div className="col-md-4 mb-4 d-none d-md-block" id="branch-drive-thru-hours">
              <h3>Horas de Autoservicio</h3>
              <DailyHours lang={"es"} {...translateDriveUpHours()} />
            </div>
          )}
          <div className="col-md-4" id="branch-services">
            {/* Branch Services */}
            <h3>Servicios de sucursal</h3>
            <BranchServices
              {...{
                services: branch.BranchServices,
                lang: "es"
              }}
            />
          </div>
          <div className="col-12 d-md-none mb-4">
            {branch.BranchImageUrl ? (
              <img
                src={branch.BranchImageUrl}
                className="border-radius-12 mw-100"
                alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
              />
            ) : null}
          </div>
        </div>
        {/* Branch Manager and Loan Officers */}
        {loanOfficers.length > 0 && (
          <div id="branch-employees">
            <h2 className="text-success">Conoce nuestro equipo de {branch.Title}</h2>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              {loanOfficers.map((loanOfficer, index) => {
                const idPrefix = `${nameToId(loanOfficer.FirstName)}-${nameToId(loanOfficer.LastName)}`;
                return (
                  <div className="col mb-4" key={index}>
                    <div className="card border-radius-12 shadow-sm">
                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col">
                            <h5 className="mb-0">
                              {loanOfficer.FirstName} {loanOfficer.LastName}
                            </h5>
                            <p className="mb-0">{loanOfficer.JobTitle?.replace(/([A-Z])/g, " $1").trim()}</p>
                          </div>
                          <div className="col-auto">
                            <BankerHeadshot email={loanOfficer.EmailAddress} width={64} height={64} />
                          </div>
                        </div>
                        <a
                          href={`mailto:${loanOfficer.EmailAddress}`}
                          className="text-decoration-none mb-3"
                          id={`${idPrefix}-email-address`}
                        >
                          <Icon name="envelope-open-text" lib="far" class="mr-2" />
                          {loanOfficer.EmailAddress}
                        </a>
                        {loanOfficer.PhoneNumber && (
                          <a
                            href={`tel:${loanOfficer.PhoneNumber}`}
                            className="text-decoration-none mb-3"
                            id={`${idPrefix}-phone-number`}
                          >
                            <Icon name="phone-alt" class="mr-2" />
                            {loanOfficer.PhoneNumber}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
    </SecondaryLanding>
  );
};

SpanishBranchTemplate.propTypes = {
  branch: PropTypes.object
};

export default SpanishBranchTemplate;
